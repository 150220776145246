import React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import theme from './theme';

export const portfolioState = atom({
  key: 'editingPortfolio',
  default: null,
});

export const PortfolioDialog = (props) => {
    const {url, closeDialog} = props;

    const [portfolio, setPortfolio] = useRecoilState(portfolioState);

    function submitPortfolio(e) {
        e.preventDefault();
        var data = {
            'name': portfolio.name,
            'api_keys': {'can_edit': ['']}
        };
        fetch(url + '/portfolio' + (portfolio.id ? '/' + portfolio.id: ''), {
            method: portfolio.id ? 'PUT': 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data),
            credentials: 'include',
        })
        .then(data => {
            if (data.ok) {
                data.text().then((data => {
                    console.log('Update portfolio - success:', data);
                    closeDialog(null, true);
                }));
            } else {
                data.text().then(data => {
                    console.log('Update portfolio - failure:', data);
                })
            }
        })
        .catch((error) => {
            console.error('Portfolio Update Error:', error);
        });
    }
    function deletePortfolio(e) {
        e.preventDefault();
        fetch(url + '/portfolio' + (portfolio.id ? '/' + portfolio.id: ''), {
            method: 'DELETE',
            credentials: 'include'
        })
        .then(data => {
            if (data.ok) {
                data.text().then((data => {
                    console.log('Delete portfolio - success:', data);
                    closeDialog(null, true);
                }));
            } else {
                data.text().then(data => {
                    console.log('Delete portfolio - failure:', data);
                })
            }
        })
        .catch((error) => {
            console.error('Portfolio Delete Error:', error);
        });
    }
    return <Dialog open={portfolio != null} onClose={closeDialog}
            aria-labelledby="form-dialog-title">
        <DialogTitle>{portfolio && portfolio.id ? 'Edit': 'Create'} Portfolio
            <IconButton aria-label="close" sx={{
                    position: 'absolute', right: theme.spacing(1),
                    top: theme.spacing(1), color: theme.palette.grey[500]
                }} onClick={closeDialog} size="large">
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Please give the nice portfolio a name.
            </DialogContentText>
            <TextField autoFocus margin="dense" id="registerName" label="Name" type="text" fullWidth
                value={portfolio ? portfolio.name || '': ''} onChange={e => setPortfolio((p) => ({...p, name: e.target.value}))} />
        </DialogContent>
        <DialogActions>
            <Button onClick={submitPortfolio} color="primary">Save</Button>
            {portfolio && portfolio.canDelete ?
                <Button onClick={deletePortfolio} color="primary">Delete</Button>
                : null}
            <Button onClick={closeDialog} color="primary">Cancel</Button>
        </DialogActions>
    </Dialog>
}
