import React from 'react';
import theme from './theme';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import { red } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import RefreshIcon from '@mui/icons-material/Refresh';
import numeral from 'numeral';
import 'numeral/locales';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
  useLocation,
  Redirect
} from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { DataGrid } from '@mui/x-data-grid';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import SubjectIcon from '@mui/icons-material/Subject';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Autocomplete from '@mui/material/Autocomplete';
//import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { compareAsc, format, parseISO, formatISO, isValid } from 'date-fns'
import Fab from '@mui/material/Fab';
import { portfolioState, PortfolioDialog } from './Portfolio.js';

numeral.locale('en-gb');

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
        Wealthr
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  floatRight: {
    float: 'right',
    padding: 0
//    paddingBottom: 0,
  },
  nowrap: {
    'white-space': 'nowrap'
  },
  scrollBar: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300
  }
}));

const transactionState = atom({
  key: 'editingTransaction', // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

const RegistrationDialog = (props) => {

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [registrationName, setRegistrationName] = React.useState('');
  const [registrationEmail, setRegistrationEmail] = React.useState('');
  const [registrationPassword, setRegistrationPassword] = React.useState('');

  function submitRegistration(e) {
    e.preventDefault();
    console.log("let's try and register");
    executeRecaptcha("register").then(token => {

        console.log('Token', token);
    var data = {
        'name': registrationName,
        'email': registrationEmail,
        'password': registrationPassword,
        'token': token,
    };

    fetch(props.url + '/register', {
      method: 'POST', // or 'PUT'
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data),
      credentials: 'include',
    })
//    .then(response => response.text())
    .then(data => {
      if (data.ok) {
        data.json().then((data => {
           console.log('Registration Success:', data);
        }));
      } else {
        data.text().then(data => {
            console.log('Registration Failed:', data);
        })
      }
    })
    .catch((error) => {
      console.error('Registration Error:', error);
    });
    });

  }

    return <Dialog open={props.registrationOpen} onClose={props.closeRegistrationDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="registration-dialog-title">Sign Up</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in these nice details.
          </DialogContentText>
          <TextField autoFocus margin="dense" id="registerName" label="Name" type="text" fullWidth
          value={registrationName} onChange={e => setRegistrationName(e.target.value)} />
          <TextField margin="dense" id="registerEmail" label="Email Address" type="email" fullWidth
          value={registrationEmail} onChange={e => setRegistrationEmail(e.target.value)} />
          <TextField margin="dense" id="registerPassword" label="Password" type="password" fullWidth
          value={registrationPassword} onChange={e => setRegistrationPassword(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.closeRegistrationDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={submitRegistration} color="primary">
            Sign Up
          </Button>
        </DialogActions>
      </Dialog>
}


const columns = [
//  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'transaction_date', headerName: 'Transaction Date', width: 200},
  { field: 'description', headerName: 'Description', width: 250},
  { field: 'reference', headerName: 'Reference', width: 130},
  { field: 'amount', headerName: 'Amount', width: 130, type: 'number' },
  { field: 'currency', headerName: 'Currency', width: 130 },
  { field: 'quantity', headerName: 'Quantity', width: 130, type: 'number' },
  { field: 'price', headerName: 'Price', width: 130 },
  { field: 'instrument_id', headerName: 'Instrument', width: 130 },
  { field: 'portfolio_instrument_id', headerName: 'Portfolio Instrument', width: 130 },
  { field: 'market_codes', headerName: 'Codes', width: 250,
    valueFormatter: (x) => x.value ? Object.keys(x.value).map((k) => k + ': ' + x.value[k]): null
    },
];

function TransactionsView(props) {
    const [transactions, setTransactions] = React.useState([]);
    const [transactionsLoaded, setTransactionsLoaded] = React.useState(false);

    function getTransactions(portfolioId) {
        fetch(props.url + '/portfolio/' + portfolioId + '/transaction', {credentials: 'include'})
          .then(res => res.json())
          .then(
            (result) => {
                console.log('got transactions result')
              setTransactionsLoaded(true);
              setTransactions(result.data || []);
            },
            (error) => {
              setTransactionsLoaded(true);
              props.setError(error);
            }
        );
    }

    React.useEffect(() => {
        console.log('init transactions view', props.isLoaded, props.positions.length, transactionsLoaded);
        if (props.isLoaded && props.positions.length && !transactionsLoaded) {
            console.log('Attempting to load transactions')
            props.positions.map((p) => {
                getTransactions(p.id);
            })
        }
    }, [props.isLoaded, props.positions, transactionsLoaded]);

    if (transactionsLoaded) {
        return <div style={{ height: 500, width: '100%' }}>
          <DataGrid rows={transactions} columns={columns} pageSize={20} rowsPerPageOptions={[20]} />
        </div>
    } else {
        return <CircularProgress />
    }
  }

const instrumentColumns = [
  { field: 'description', headerName: 'Description', width: 250},
  { field: 'currency', headerName: 'Currency', width: 130 },
  { field: 'market_code', headerName: 'Code', width: 250 }
];

function InstrumentsView(props) {
    const [instruments, setInstruments] = React.useState([]);
    const [instrumentsLoaded, setInstrumentsLoaded] = React.useState(false);

    function getInstruments(portfolioId) {
        fetch(props.url + '/portfolio/' + portfolioId + '/instrument', {credentials: 'include'})
          .then(res => res.json())
          .then(
            (result) => {
                console.log('got instruments result')
              setInstrumentsLoaded(true);
              setInstruments(result.data || []);
            },
            (error) => {
              setInstrumentsLoaded(true);
              props.setError(error);
            }
        );
    }

    React.useEffect(() => {
        console.log('init instruments view', props.isLoaded, props.positions.length, instrumentsLoaded);
        if (props.isLoaded && props.positions.length && !instrumentsLoaded) {
            console.log('Attempting to load instruments')
            props.positions.map((p) => {
                getInstruments(p.id);
            })
        }
    }, [props.isLoaded, props.positions, instrumentsLoaded]);

    if (instrumentsLoaded) {
        return <div style={{ height: 500, width: '100%' }}>
          <DataGrid rows={instruments} columns={instrumentColumns} pageSize={20} rowsPerPageOptions={[20]} />
        </div>
    } else {
        return <CircularProgress />
    }
}

function TransactionListItem(props) {

    const {t, portfolioId, classes, openTransactionDialog} = props;
    return (
      <ListItem>
           <ListItemText primary={<Typography variant="body2">{t.description}</Typography>}
          secondary={<span>{
              ((t.quantity && t.price) ?
                  t.quantity + '@' + numeral(t.price).format('0,0.00'):
                  t.amount) + ' ' + t.currency
          }
          <span className={classes.floatRight}>
              {format(parseISO(t.transaction_date), 'd MMM yyyy')}
          </span>
          </span>}>
          </ListItemText>
          <ListItemSecondaryAction>
            <Tooltip title="Edit transaction">
            <IconButton className={classes.floatRight} size="large"
                onClick={() => { openTransactionDialog(t, portfolioId); }}>
              <EditIcon/>
            </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
    );
}

function InstrumentPicker(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.instrument_code || null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [market, setMarket] = React.useState('Yahoo Finance');
  const handleMarket = (event, newMarket) => {
    setMarket(newMarket);
  };
  const setEditingTransaction = useSetRecoilState(transactionState);

  const iFetch = React.useMemo(
    () => throttle((request, callback) => {
          fetch(props.url + '/instrument/search/' + request.input, {credentials: 'include'})
          .then(res => res.json())
          .then((results) => callback(results))
        }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    iFetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results.data) {
          newOptions = [...newOptions, ...results.data];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, iFetch]);

  return (
    <React.Fragment>
    {/*
    <Grid item md={5} xs={12}>
    <ToggleButtonGroup
      value={market}
      exclusive
      onChange={handleMarket}>
      <ToggleButton value="Yahoo Finance">Yahoo</ToggleButton>
      <ToggleButton value="City Index">City Index</ToggleButton>
      <ToggleButton value="Local">Local</ToggleButton>
    </ToggleButtonGroup>
    </Grid>*/}
    <Grid item md={12} xs={12}>
    <Autocomplete id="select"
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.symbol)}
        filterOptions={(x) => x} options={options} autoComplete includeInputInList
        filterSelectedOptions value={value}
        onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            setEditingTransaction((currentTransaction) => {
                var newTransaction = {...currentTransaction};
                if (newValue) {
                    console.log('updating instrument on transaction', newValue.symbol);
                    newTransaction.instrument_code = newValue.symbol;
                } else {
                    console.log('removing instrument from transaction');
                    delete newTransaction.instrument_code;
                }
                delete newTransaction.instrument_id;
                delete newTransaction.portfolio_instrument_id;
                return newTransaction;
            });
        }}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        renderInput={(params) => (
            <TextField {...params} label="Instrument" variant="outlined" fullWidth />
        )}
      renderOption={(props, option) => {
//        const matches = option.structured_formatting.main_text_matched_substrings;
//        const parts = parse(
//          option.structured_formatting.main_text,
//          matches.map((match) => [match.offset, match.offset + match.length]),
//        );

        return <li {...props}>
          <Grid container alignItems="center">
            {/*<Grid item>
              <AccountBalanceIcon />
            </Grid>*/}
            <Grid item xs>
              {/*parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))*/}
              <Typography>{option.symbol}</Typography>
              <Typography variant="body2" color="textSecondary">
                {option.longname}
              </Typography>
            </Grid>
          </Grid>
        </li>;
      }}
    />
    </Grid>
    </React.Fragment>
  );
}

function TransactionDialog(props) {

  const {portfolioId, dialogOpen, closeDialog, url} = props;
  const [transaction, setTransaction] = useRecoilState(transactionState);
//  const [transaction, setTransaction] = React.useState({});

    function actuallySubmitTransaction(data, tId, pId) {

        fetch(url + '/portfolio/' + pId + '/transaction' + (tId ? '/'+ tId: ''), {
            method: tId ? 'PUT': 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data),
            credentials: 'include',
        })
    //    .then(response => response.text())
        .then(data => {
            if (data.ok) {
                data.text().then((data => {
                   console.log('Transaction Edited:', data);
                   closeDialog(null, true);
                }));
            } else {
                data.text().then(data => {
                    //TODO: warn/error
                    console.log('Transaction Update Failed:', data);
                })
            }
        })
        .catch((error) => {
          //TODO: warn/error
            console.error('Transaction Update Error:', error);
        });
    }

    function submitTransaction(e) {
        e.preventDefault();
        console.log("let's edit a transaction");

        if (! transaction.transaction_date) {
            console.log('date', transaction.transaction_date, typeof transaction.transaction_date);
            return
        }
        if (!isValid(transaction.transaction_date)) {
            console.log('Date is not valid (according to fns)', transaction.transaction_date);
            return;
        }

        var data = {...transaction};
        const pId = portfolioId;
        const tId = transaction.id;
        delete data.id;
        if (data.transaction_type) {
            console.log('Deleting transaction type: ', data.transaction_type);
            delete data.transaction_type;
        }
        console.log('Transaction date before', data.transaction_date);
        data.transaction_date = format(parseISO(data.transaction_date.toISOString()), "yyyy-MM-dd'T'HH:mm:ss");
        console.log('Transaction date after', data.transaction_date);

        if (data.instrument_code && !data.instrument_id) {
            console.log('Looking up instrument id')
            fetch(url + '/instrument/search/' + data.instrument_code, {
                method: 'POST',
                credentials: 'include',
            }).then(res => res.json())
            .then((results) => {
                console.log('got results:', results);
                results.data.map((x) => {
                    if (x.symbol == data.instrument_code && x.instrument_id) {
                        console.log('Replacing instrument code:', data.instrument_code,
                            'with instrument id:', x.instrument_id);
                        data.instrument_id = x.instrument_id;
                        delete data.instrument_code;
                    }
                });
                actuallySubmitTransaction(data, tId, pId);
            })
        } else {
            if (data.instrument_code && data.instrument_id) {
                delete data.instrument_code;
            }
            actuallySubmitTransaction(data, tId, pId);
        }
    }

    function deleteTransaction(e) {
        e.preventDefault();
        console.log("let's delete a transaction");

        fetch(url + '/portfolio/' + portfolioId + '/transaction/' + transaction.id, {
            method: 'DELETE',
        //      headers: {'Content-Type': 'application/json'},
        //      body: JSON.stringify(data),
            credentials: 'include',
        })
        .then(data => {
            if (data.ok) {
                data.json().then((data => {
                console.log('Transaction Deleted:', data);
                closeDialog(null, true);
                }));
            } else {
                data.text().then(data => {
                    //TODO: set error / warn
                    console.log('Transaction Delete Failed:', data);
                })
            }
        })
        .catch((error) => {
            //TODO: set error / warn
            console.error('Transaction Delete Error:', error);
        });
    }

    const getValue = (field) =>
        transaction && transaction[field] != undefined ? transaction[field]: '';
//    const value = transaction[field] != undefined ? transaction[field]: t && t[field] ? t[field]: '';
//    console.log('Field', field, 'value', value);

    const getTransactionType = () =>
    getValue('transaction_type') || (getValue('quantity') || getValue('price') ? 'Trade': 'Payment')

    return <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle id="edit-transaction-dialog-title">
            Edit Transaction
           <IconButton aria-label="close" sx={{
                position: 'absolute',
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500]
           }} onClick={closeDialog} size="large">
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
          <Grid item md={12}>
            <ToggleButtonGroup exclusive value={getTransactionType()} onChange={(event, newTransactionType) => {
                    if (newTransactionType) {
                        setTransaction({...transaction, 'transaction_type': newTransactionType});
                    }
                }}>
              <ToggleButton value="Trade">Trade</ToggleButton>
              <ToggleButton value="Payment">Payment</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item md={12}>
              <DatePicker
                label="Transaction Date"
                value={getValue('transaction_date') || null}
                inputFormat="dd/MMM/yyyy"
                onError={(message, value) => {
                    console.log('Transaction Date Error', message, value, getValue('transaction_date'));
                }}
                disableMaskedInput={true}
                onChange={newValue => setTransaction({
                    ...transaction,
                    //transaction_date: format(parseISO(newValue.toISOString()), "yyyy-MM-dd'T'hh:mm:ss")
                    transaction_date: newValue
                })}
                renderInput={(params) => <TextField {...params}
                    error={false} />}
              />
          </Grid>
          <InstrumentPicker url={url} instrument_code={transaction.instrument_code} />
          <Grid item md={12}>
            <TextField autoFocus margin="dense" id="transactionDescription" label="Description" type="text" fullWidth
                value={getValue('description')}
            onChange={e => setTransaction({...transaction, description: e.target.value})} />
          </Grid>
          <Grid item md={12}>
            <TextField margin="dense" id="transactionReference" label="Reference" type="text" fullWidth
            value={getValue('reference')} onChange={e => setTransaction({...transaction, reference: e.target.value})} />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField margin="dense" id="transactionAmount" label="Amount" type="text"
            value={getValue('amount')} onChange={e => setTransaction({...transaction, amount: e.target.value})} />
          </Grid>
          <Grid item md={6} xs={12}>
          <TextField margin="dense" id="transactionCurrency" label="Currency" type="text"
          value={getValue('currency')} onChange={e => setTransaction({...transaction, currency: e.target.value})} />
          </Grid>
          {getTransactionType() == 'Trade'? <React.Fragment>
              <Grid item md={6} xs={12} >
                  <TextField margin="dense" id="transactionQuantity" label="Quantity" type="text"
                  value={getValue('quantity')}
                  onChange={e => setTransaction({...transaction, quantity: e.target.value})} />
              </Grid>
              <Grid item md={6} xs={12}>
                  <TextField margin="dense" id="transactionPrice" label="Price" type="text"
                  value={getValue('price')}
                  onChange={e => setTransaction({...transaction, price: e.target.value})} />
              </Grid>
          </React.Fragment>: null
          }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={submitTransaction} color="primary">
            Save
          </Button>
          {transaction && transaction.id ?
          <Button onClick={deleteTransaction} color="primary">
            Delete
          </Button>: null}
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
}

function MyApp() {

  var url = 'https://data.wealthr.app';
  if (window.location.href.indexOf(':3000/') > -1) {
    url = window.location.protocol + '//' + window.location.hostname + ':8000';
  }

  React.useEffect(() => {
    console.log('current url: ', window.location.href);
    console.log('backend url', url);
  }, [url]);

  const classes = useStyles();
  const [isLoaded, setLoaded] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [loginEmail, setLoginEmail] = React.useState('');
  const [loginPassword, setLoginPassword] = React.useState('');
  const [registrationOpen, setRegistrationOpen] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [positions, setPositions] = React.useState({});
  const [openPortfolios, setOpenPortfolios] = React.useState({});
  const [expandedPortfolios, setExpandedPortfolios] = React.useState({});
  const [openPositions, setOpenPositions] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [timestamp, setTimestamp] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const setEditingTransaction = useSetRecoilState(transactionState);
  const [editingPortfolioId, setEditingPortfolioId] = React.useState(null);
  const [transactionDialogOpen, setTransactionDialogOpen] = React.useState(false);
  const setEditingPortfolio = useSetRecoilState(portfolioState);

  const openTransactionDialog = (t, p_id) => {
    console.log('Opening transaction dialog', t.description, p_id);
    setEditingTransaction({...t, transaction_date: parseISO(t.transaction_date)});
    setEditingPortfolioId(p_id);
    setTransactionDialogOpen(true);
  }

  const openPortfolioDialog = (p) => {
    console.log('Opening portfolio dialog', p);
    setEditingPortfolio(p);
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMenuOpen(open);
  };
  const renderLink =
    () => React.forwardRef((itemProps, ref) => <RouterLink ref={ref} {...itemProps} />);

    function openLoginDialog(e) {
        e.preventDefault();
        console.log("let's login");
        setLoginOpen(true);
    }

    function closeLoginDialog(e) {
        e.preventDefault();
        setLoginOpen(false);
    }

    function getPositions() {
        fetch(url, {credentials: 'include'})
            .then(res => res.json())
            .then(
                (result) => {
                  setLoaded(true);
                  setReload(false);
                  setUser(result.user || {});
                  setPositions(result.portfolios || []);
                  setTimestamp(new Date());
                },
                (error) => {
                  setLoaded(true);
                  setReload(false);
                  setError(error);
                }
            );
  }

  function submitLogin(e) {
    e.preventDefault();
    console.log("let's try and login");
    var data = {
        'email': loginEmail,
        'password': loginPassword,
    };

    fetch(url + '/login', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data),
      credentials: 'include',
    })
//    .then(response => response.json())
    .then(data => {
      console.log('Login Response:', data);
      if (data.ok) {
        setLoaded(false);
        setLoginOpen(false);
        setLoginEmail('');
        setLoginPassword('');
      }
    })
    .catch((error) => {
      console.error('Login Error:', error);
    });
  }

  function handleLogout(e) {
    console.log("let's logout");
    fetch(url + '/logout', {
      credentials: 'include',
    })
    .then(data => {
      console.log('Logout Success:', data);
      setLoaded(false);
      setAnchorEl(null);
    })
    .catch((error) => {
      console.error('Logout Error:', error);
    });

  }

  function openRegistrationDialog(e) {
    e.preventDefault();
    console.log("let's register");
    setRegistrationOpen(true);
  }

  function closeRegistrationDialog(e) {
    e.preventDefault();
    setRegistrationOpen(false);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

    const togglePositionOpen = (id) => {
        var positions = {...openPositions};
        positions[id] = !positions[id];
        setOpenPositions(positions);
    }

    const togglePortolioOpen = (id) => {
        var portfolios = {...openPortfolios};
        portfolios[id] = !portfolios[id];
        setOpenPortfolios(portfolios);
    }

    const togglePortfolioExpanded = (id) => {
        var portfolios = {...expandedPortfolios};
        portfolios[id] = !portfolios[id];
        setExpandedPortfolios(portfolios);
    }

  React.useEffect(() => {
      if ((! isLoaded) || reload) {
          console.log('gonna get positions', isLoaded, reload);
          getPositions();
      };
  }, [isLoaded, reload])

  if (isLoaded) return (
    <LocalizationProvider dateAdapter={DateAdapter}>
    <Router>

      <Dialog open={loginOpen} onClose={closeLoginDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="login-dialog-title">Login</DialogTitle>
        <DialogContent>
          <TextField autoFocus margin="dense" id="loginEmail" label="Email Address" type="email" fullWidth
          value={loginEmail} onChange={e => setLoginEmail(e.target.value)} />
          <TextField margin="dense" id="loginPassword" label="Password" type="password" fullWidth
          value={loginPassword} onChange={e => setLoginPassword(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeLoginDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={submitLogin} color="primary">
            Login
          </Button>
        </DialogActions>
      </Dialog>

    <GoogleReCaptchaProvider reCaptchaKey="6Ld3-JsaAAAAAJziBpNQLhbE_OW7hZJHFjg0GGKl">
      <RegistrationDialog url={url} registrationOpen={registrationOpen}
       closeRegistrationDialog={closeRegistrationDialog} />
    </GoogleReCaptchaProvider>

      <Drawer open={menuOpen} onClose={toggleDrawer(false)}>
          <div
      className={clsx(classes.list, {
        [classes.fullList]: true,
      })}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}>
         <List>
            <ListItem button key="Positions" component={renderLink()} to="/positions">
                <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
                <ListItemText primary="Positions" />
            </ListItem>
            <ListItem button key="Transactions" component={renderLink()} to="/transactions">
                <ListItemIcon><ViewHeadlineIcon /></ListItemIcon>
                <ListItemText primary="Transactions" />
            </ListItem>
            <ListItem button key="Instruments" component={renderLink()} to="/instruments">
                <ListItemIcon><SubjectIcon /></ListItemIcon>
                <ListItemText primary="Instruments" />
            </ListItem>
        </List>
        </div>
      </Drawer>

      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            size="large">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            <Switch>
                <Route path="/positions">Positions</Route>
                <Route path="/transactions">Transactions</Route>
                <Route path="/instruments">Instruments</Route>
            </Switch>
          </Typography>
          { user.user_id ?

            <div>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
                size="large">
                <AccountCircle fontSize="large"/>
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleLogout}><ExitToAppIcon /> Logout</MenuItem>
              </Menu>
            </div>:
            <div>
              <Button color="inherit" onClick={openLoginDialog}>Login</Button>/
              <Button color="inherit" onClick={openRegistrationDialog}>Sign Up</Button>
            </div>
          }
        </Toolbar>
      </AppBar>

    <Container maxWidth="lg">

    <Switch>
        <Redirect from="/" exact to="/positions" />
        <Route path="/positions">

        <Box my={4}>
        <TransactionDialog portfolioId={editingPortfolioId} dialogOpen={transactionDialogOpen}
            url={url} closeDialog={(e, edited) => {
                console.log('Closing transaction dialog', edited);
                setEditingPortfolioId(null);
                setTransactionDialogOpen(false);
                if (edited) {
                    setReload(true);
                }
            }} />
        <PortfolioDialog url={url} closeDialog={(e, edited) => {
            console.log('Closing portfolio dialog', edited)
            setEditingPortfolio(null);
            if (edited) {
                setReload(true);
            }
        }}/>
        <Typography variant="h4" component="h1" gutterBottom>
            {user.name || 'Hello'}
        </Typography>
        <Grid container spacing={3}>
            {/*
          <Grid item xs={12} sm={6} md={3} key="XRO">
            <Card>
              <CardHeader action={
                  <IconButton aria-label="settings">
                    <MoreVertIcon />
                  </IconButton>
                }
              avatar={
                <Avatar className={classes.avatar}>XRO</Avatar>} title={
                <React.Fragment>
                    <Typography>The Portfolio</Typography>
                    <Badge badgeContent="+349%">
                        <Chip avatar={
                          <Avatar><TrendingUpIcon /></Avatar>
                        } label={<Typography variant="h6">£22,232.12</Typography>}>
                        </Chip>
                    </Badge>
                </React.Fragment>
              } subheader="£16,002, 4.87 years, +28.4%">
              </CardHeader>
              <CardContent>
                <Grid container justify="space-between">
                  <Box m={1}>
                    <Typography variant="body2">1 year</Typography>
                    <Badge badgeContent="+39%">
                      <Chip avatar={
                        <Avatar><TrendingUpIcon /></Avatar>
                      } label="47.76">
                      </Chip>
                    </Badge>
                  </Box>
                  <Box m={1}>
                    <Typography variant="body2">1 month</Typography>
                    <Badge badgeContent="+8.9%">
                      <Chip avatar={
                        <Avatar><TrendingUpIcon /></Avatar>
                      } label="61.08">
                      </Chip>
                    </Badge>
                  </Box>
                </Grid>
                <Grid container justify="space-between">
                  <Box m={1}>
                    <Typography variant="body2">7 days</Typography>
                    <Badge badgeContent="+4.7%">
                      <Chip avatar={
                        <Avatar><TrendingUpIcon /></Avatar>
                      } label="63.47">
                      </Chip>
                    </Badge>
                  </Box>
                  <Box m={1}>
                    <Typography variant="body2">Today</Typography>
                    <Badge badgeContent="0%">
                      <Chip  avatar={
                        <Avatar><TrendingFlatIcon /></Avatar>
                      } label="66.5">
                      </Chip>
                    </Badge>
                  </Box>
                </Grid>
              </CardContent>

              <CardActions disableSpacing>
                <Typography>1 trade</Typography>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography variant="body2" paragraph>60@AU$14.8 on 27 Oct 2014</Typography>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
          */}
          { positions && positions.length ? positions.map((p) => [
                <Grid item xs={12} sm={6} md={3} key={p.id}>
                    <Card>
                        {/*avatar={<Avatar className={classes.avatar} variant="rounded">
                                    {p.name.substring(0, 3)}</Avatar>}*/}
                        <CardHeader
                            title={
                                <React.Fragment>
                                <Typography>{p.name}
                                <span className={classes.floatRight}>
                                {p.invalid_transactions.length || p.positions.length?
                                <IconButton onClick={() => {togglePortfolioExpanded(p.id)}} size="large" sx={{p: 1}}>
                                {p.invalid_transactions.length ?
                                <Tooltip title="Unmapped transactions">
                                <Badge badgeContent={p.invalid_transactions.length} color="primary">
                                    <ErrorOutlineOutlinedIcon />
                                </Badge></Tooltip>:
                                <Tooltip title="More"><MoreHorizIcon/></Tooltip>}
                                </IconButton>:null}
                                {p.positions.length ?
                                <Tooltip title={(openPortfolios[p.id] ? "Hide": "Show") + " Instruments"}>
                                <IconButton onClick={() => togglePortolioOpen(p.id)} size="large" sx={{p: 1}}>
                                    {/*openPortfolios[p.id] ? <ExpandLessIcon/>: <ExpandMoreIcon /> */}
                                    {openPortfolios[p.id] ? <RemoveCircleOutlineIcon/>: <AddCircleOutlineIcon />}
                                </IconButton>
                                </Tooltip>:null}
                                </span>
                                </Typography>
                                <Badge badgeContent={
                                p.open_profit == 0 ? null: numeral(p.open_profit / 100).format('+%')}>
                                <Chip avatar={
                                  <Avatar>{
                                    p.open_profit > 0 ? <TrendingUpIcon />:
                                        !p.open_profit ? <TrendingFlatIcon />: <TrendingDownIcon />
                                    }</Avatar>
                                }
                                    label={<Typography variant="h6">{numeral(p.open_value).format('$0,0')}</Typography>}>
                                </Chip>
                                </Badge>
                                </React.Fragment>
                            } subheader={<Typography variant="caption" noWrap={true}>{
                                (p.all_time_pnl > 0 ? '+': '') +
                                numeral(p.all_time_pnl).format('$0,0') + ' / ' +
                                numeral(p.all_time_days / 365).format('0.0') + ' years / ' +
                                numeral(p.all_time_annualised_profit / 100).format('+%') + 'pa'
                                }</Typography>
                            }>
                        </CardHeader>
                        <CardContent sx={{py: 0}}>
                            <Box display="flex" justifyContent="space-between" flexWrap="nowrap">
                            { p.price_movements && p.price_movements['1d'] != undefined ? <Badge badgeContent="1d">
                                <Chip size="small" color={
                                    p.price_movements['1d'] >= 0 ? 'secondary': 'primary'
                                } variant="outlined" label={
                                    numeral(p.price_movements['1d'] / 100).format('+%')
                                } />
                            </Badge>: null }
                            { p.price_movements && p.price_movements['1w'] != undefined ? <Badge badgeContent="1w">
                                <Chip size="small" color={
                                    p.price_movements['1w'] >= 0 ? 'secondary': 'primary'
                                } variant="outlined" label={
                                    numeral(p.price_movements['1w'] / 100).format('+%')
                                } />
                            </Badge>: null }
                            { p.price_movements && p.price_movements['1m'] != undefined ? <Badge badgeContent="1m">
                                <Chip size="small" color={
                                    p.price_movements['1m'] >= 0 ? 'secondary': 'primary'
                                } variant="outlined" label={
                                    numeral(p.price_movements['1m'] / 100).format('+%')
                                } />
                            </Badge>: null }
                            { p.price_movements && p.price_movements['1y'] != undefined ? <Badge badgeContent="1y">
                                <Chip size="small" color={
                                    p.price_movements['1y'] >= 0 ? 'secondary': 'primary'
                                } variant="outlined" label={
                                    numeral(p.price_movements['1y'] / 100).format('+%')
                                } />
                            </Badge>: null }
                            </Box>
                        </CardContent>
                              <Collapse in={expandedPortfolios[p.id]||
                                (!p.invalid_transactions.length && !p.positions.length)
                              } timeout="auto" unmountOnExit>
                              {expandedPortfolios[p.id] ? <CardContent sx={{p: 0}}>
                                <List className={classes.scrollBar}>
                                {p.invalid_transactions.map((t, i) =>
                                  <React.Fragment key={t.id}>
                                  <TransactionListItem t={t} portfolioId={p.id} classes={classes}
                                    openTransactionDialog={openTransactionDialog} />
                                  {p.invalid_transactions.length > i + 1 ? <Divider />: null}
                                  </React.Fragment>
                                )}
                                </List>
                              </CardContent>:null}
                              <CardActions sx={{'display': 'flex', 'justifyContent': 'space-between', p: 0}}>
                                <Tooltip title="Import transactions">
                                <IconButton onClick={() => { }}>
                                  <FileUploadIcon />
                                </IconButton>
                                </Tooltip>
                                <Tooltip title="Add a transaction">
                                <IconButton onClick={() => { openTransactionDialog({}, p.id)}}>
                                  <AddIcon/>
                                </IconButton>
                                </Tooltip>
                                <Tooltip title="Portfolio Settings">
                                <IconButton onClick={() => { openPortfolioDialog({
                                    id: p.id,
                                    name: p.name,
                                    canDelete: !p.positions.length && !p.invalid_transactions.length
                                })}}>
                                  <SettingsIcon />
                                </IconButton>
                                </Tooltip>
                              </CardActions>
                              </Collapse>
                        </Card>
                </Grid>,
                openPortfolios[p.id] ? p.positions.map((pp) => (
                    <Grid item xs={12} sm={6} md={3} key={ pp.instrument_code }>
                    <Card>
                        <CardHeader avatar={true?
                            <Avatar className={classes.avatar} variant="rounded">{
                                pp.instrument_code.substring(0, 3)
                            }</Avatar>: null
                        } title={
                            <React.Fragment>
                                <Typography>{false? pp.instrument_code: null}
                                    <IconButton className={classes.floatRight} onClick={
                                        () => togglePositionOpen(
                                            p.id + (pp.instrument_id || pp.portfolio_instrument_id)
                                        )
                                    } size="large">
                                    <Tooltip title={pp.transactions.length + ' Transactions'}>
                                        {/*<Badge badgeContent={pp.transactions.length} color="secondary">*/}
                                        <ViewHeadlineIcon />
                                        {/*</Badge>*/}
                                    </Tooltip>
                                    </IconButton>
                                </Typography>
                                <Badge badgeContent={
                                    pp.open_profit == 0 ? null: numeral(pp.open_profit / 100).format('+%')
                                }>
                                <Chip avatar={
                                    <Avatar>{
                                        pp.open_profit > 0 ? <TrendingUpIcon />:
                                            !pp.open_profit ? <TrendingFlatIcon />: <TrendingDownIcon />
                                    }</Avatar>
                                    } label={
                                    <Typography variant="h6">
                                        {numeral(pp.open_value).format('$0,0')}
                                    </Typography>}>
                                </Chip>
                              </Badge>
                              </React.Fragment>
                          } subheader={ //£16,002, 4.87 years, +28.4%">
                          /*<span className={classes.nowrap}>{*/
                            <Typography variant="caption" noWrap={true}>
                            {(pp.all_time_pnl > 0 ? '+': '') +
                            numeral(pp.all_time_pnl).format('$0,0') + ' / ' +
                            numeral(pp.all_time_days / 365).format('0.0') + ' years / ' +
                            numeral(pp.all_time_annualised_profit / 100).format('+%') + 'pa'}
                            </Typography>
                            /*}</span>*/
                          }>
                        </CardHeader>
                        <CardContent sx={{py: 0}}>
                        <Box display="flex" justifyContent="space-between" flexWrap="nowrap">
                            { pp.price_movements && pp.price_movements['1d'] != undefined ? <Badge badgeContent="1d">
                                <Chip size="small" color={
                                    pp.price_movements['1d'] >= 0 ? 'secondary': 'primary'
                                } variant="outlined" label={
                                    numeral(pp.price_movements['1d'] / 100).format('+%')
                                } />
                            </Badge>: null }
                            { pp.price_movements && pp.price_movements['1w'] != undefined ? <Badge badgeContent="1w">
                                <Chip size="small" color={
                                    pp.price_movements['1w'] >= 0 ? 'secondary': 'primary'
                                } variant="outlined" label={
                                    numeral(pp.price_movements['1w'] / 100).format('+%')
                                } />
                            </Badge>: null }
                            { pp.price_movements && pp.price_movements['1m'] != undefined ? <Badge badgeContent="1m">
                                <Chip size="small" color={
                                    pp.price_movements['1m'] >= 0 ? 'secondary': 'primary'
                                } variant="outlined" label={
                                    numeral(pp.price_movements['1m'] / 100).format('+%')
                                } />
                            </Badge>: null }
                            { pp.price_movements && pp.price_movements['1y'] != undefined ? <Badge badgeContent="1y">
                                <Chip size="small" color={
                                    pp.price_movements['1y'] >= 0 ? 'secondary': 'primary'
                                } variant="outlined" label={
                                    numeral(pp.price_movements['1y'] / 100).format('+%')
                                } />
                            </Badge>: null }
                        </Box>
                        </CardContent>
                        { openPositions[p.id + (pp.instrument_id || pp.portfolio_instrument_id)] ?
                            <React.Fragment>
                            <CardContent sx={{pb: 0}} align="center">
                                <Chip label={
                                    'Holding: ' + pp.quantity + '@' + numeral(pp.latest_price).format('$0,0.00')
                                }/>
                            </CardContent>
                            <CardContent sx={{p: 0}}>
                                <List className={classes.scrollBar}>
                                    {pp.transactions.map((t, i) =>
                                    <React.Fragment key={t.id}>
                                    <TransactionListItem t={t} portfolioId={p.id} classes={classes}
                                        openTransactionDialog={openTransactionDialog} />
                                        {pp.transactions.length > i + 1 ? <Divider />: null}
                                    </React.Fragment>
                                    )}
                                </List>
                            </CardContent>
                            <CardActions sx={{'display': 'flex', 'justifyContent': 'center', p: 0}}>
                                <Tooltip title="Add a transaction">
                                <IconButton onClick={() => {
                                    openTransactionDialog({
                                        instrument_code: pp.instrument_code
                                        }, p.id)}
                                }>
                                  <AddIcon/>
                                </IconButton>
                                </Tooltip>
                              </CardActions>
                        </React.Fragment>: null}
                    </Card>
                    </Grid>
                )): null,
            ]): null}
        </Grid>
        <Box textAlign="center" my={2}>
            <Tooltip title="Add New Portfolio">
                <Fab color="primary" aria-label="add" onClick={
                    () => openPortfolioDialog({})
                }>
                    <AddIcon />
                </Fab>
            </Tooltip>
        </Box>
        {timestamp ?<Typography variant="body2" align="center">
            { format(timestamp, 'EEE MMM d yyyy HH:mm O') }
        </Typography>: null}
        <Copyright />
        </Box>
        </Route>
        <Route path="/transactions">
            <Box my={4}>
                <TransactionsView setError={setError} url={url} positions={positions} isLoaded={isLoaded}/>
            </Box>
        </Route>
        <Route path="/instruments">
            <Box my={4}>
                <InstrumentsView setError={setError} url={url} positions={positions} isLoaded={isLoaded}/>
            </Box>
        </Route>
    </Switch>
    </Container>
    </Router>
    </LocalizationProvider>
  ); else return (
    <CircularProgress />
  );
}

export default function App() {
    return <RecoilRoot>
        <MyApp />
    </RecoilRoot>
}
